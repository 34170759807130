<template>
  <div>
    <highcharts :id="id" :options="Options" :data="data" v-if="isMounted"></highcharts>
  </div>
</template>

<script>
import HighCharts from 'highcharts'
import { isCSR } from '@/utils'
if (isCSR()) {
  require('@/plugins/highcharts.js')
}

export default {
  props: {
    id: {
      type: String
    },
    data: {
      type: Array
    }
  },
  watch: {
    data() {
      HighCharts.chart(this.id, this.Options)
    }
  },
  mounted() {
    this.isMounted = true
    const alreadyInited = !!this.data
    if (!alreadyInited) HighCharts.chart(this.id, this.Options)
  },
  data() {
    return {
      isMounted: false,
      Options: {
        legend: {
          enabled: false
        },
        title: {
          text: null
        },
        yAxis: {
          title: null,
          opposite: false
        },
        xAxis: {
          break: {
            breakSize: 0
          },
          categories: this.data.map(e => `${e[0].split('/')[1]}/${e[0].split('/')[0]}`)
        },
        rangeSelector: {
          enabled: false
        },
        credits: {
          enabled: false
        },
        scrollbar: {
          enabled: false
        },
        navigator: {
          enabled: false
        },
        plotOptions: {
          candlestick: {
            tooltip: {
              pointFormat:
                '開盤: {point.open}<br/>' +
                '最高: {point.high}<br/>' +
                '最低: {point.low}<br/>' +
                '收盤: {point.close}<br/>'
            }
          }
        },
        series: [
          {
            name: '1',
            type: 'candlestick',
            data: this.data,
            tooltip: {
              valueDecimals: 4
            }
          }
        ],
        responsive: {
          rules: [
            {
              condition: {
                maxWidth: 500
              },
              chartOptions: {
                legend: {
                  layout: 'horizontal',
                  align: 'center',
                  verticalAlign: 'bottom'
                }
              }
            }
          ]
        }
      }
    }
  }
}
</script>
