<template>
  <div>
    <!--<h1>市場資訊</h1>
    <div class="page-content">
      {{ marketInfo }}
    </div>
   -->
    <div class="market-news">
      <b-container class="mt-4">
        <h1 class="my-4" v-if="page">{{ page.title }}</h1>
        <div v-if="specialContent" class="my-4" v-html="specialContent"></div>
        <div v-else>
          <b-tabs content-class="mt-3" class="news-tabs" lazy justified align="center">
            <b-tab>
              <template v-slot:title>
                <div class="d-flex align-items-center justify-content-center">
                  <i class="icon icon-market-news1" :title="$t('外匯走勢分析')"></i>
                  <strong class="mx-2">{{ $t('外匯走勢分析') }}</strong>
                </div>
              </template>
              <template v-if="content">
                <div>
                  <div class="news-info my-4">
                    <h3 class="my-3">{{ $t('外匯資訊') }}</h3>
                    <p>{{ $t('外匯孖展查詢熱線:2857-2655') }}</p>
                    <p>{{ content.inputAt }}</p>
                    <p class="my-3">
                      <span>{{ $t('本期焦點 ↗上升↘下跌→反覆') }}</span>
                    </p>
                  </div>
                  <div class="box box-border my-4" v-for="outlook in content.outlook" :key="`left_${outlook.slug}`">
                    <div class="box-title">
                      {{ langName(outlook.name) }} {{ outlook.slug }}
                      {{ handleFlag(outlook.flag) }}
                    </div>
                    <ul class="d-flex flex-wrap">
                      <li class="col-12 col-sm-12 col-md-6 p-0">
                        <div class="box-branch-title px-4">{{ $t('走勢分析') }}</div>
                        <div class="p-4 analyse">
                          {{ outlook.txt | localeTxt(lang) }}
                        </div>
                      </li>
                      <li class="col-12 col-sm-12 col-md-6 p-0">
                        <div class="box-branch-title px-4">{{ $t('走勢圖') }}</div>
                        <b-container>
                          <XChart
                            :id="`charts-${outlook.slug}`"
                            :data="outlook.chart.map(e => e.map(i => Number(i) || i))"
                            v-if="isMounted"
                          ></XChart>
                        </b-container>
                      </li>
                    </ul>
                  </div>
                </div>
              </template>
            </b-tab>
            <b-tab>
              <template v-slot:title>
                <div class="d-flex align-items-center justify-content-center">
                  <i class="icon icon-market-news2" :title="$t('外匯投資策略參考')"></i>
                  <strong class="mx-2">{{ $t('外匯投資策略參考') }}</strong>
                </div>
              </template>
              <template v-if="content.strategy">
                <div class="strategy-table">
                  <div class="news-info my-4">
                    <h3 class="my-3">{{ $t('外匯資訊') }}</h3>
                    <p>{{ $t('外匯孖展查詢熱線:2857-2655') }}</p>
                    <p>{{ content.inputAt }}</p>
                  </div>
                  <p class="my-3">
                    <b>{{ $t('上日市場概況') }}</b>
                  </p>
                  <div class="box my-4">
                    <table class="w-100">
                      <thead>
                        <tr>
                          <th width="25%"></th>
                          <th>{{ $t('上日收市') }}</th>
                          <th>{{ $t('升跌') }}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="info1 in content.strategy.info1" :key="info1.name.en">
                          <td class="blue">{{ info1.name | localeTxt(lang) }}</td>
                          <td>{{ info1.value1 }}</td>
                          <td>
                            <span class="p-2">{{ info1.value2 > 0 ? '↑' : '↓' }}</span
                            >{{ Math.abs(info1.value2) }}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div class="box my-4">
                    <table class="w-100">
                      <thead>
                        <tr>
                          <th></th>
                          <th>{{ $t('上日公佈的重要經濟數據') }}</th>
                          <th>{{ $t('結果') }}</th>
                          <th>{{ $t('前值') }}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="info2 in content.strategy.info2" :key="info2.name.en">
                          <td class="blue">{{ langName(info2.name) }}</td>
                          <td>{{ info2.txt | localeTxt(lang) }}</td>
                          <td>{{ info2.result }}</td>
                          <td>{{ info2.before }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div class="box my-4">
                    <p class="my-3">
                      <b>{{ $t('匯市簡訊 ↗上升↘下跌→反覆') }}</b>
                    </p>
                    <table v-for="info3 in content.strategy.info3" :key="info3.name.en" class="w-100 bars">
                      <thead>
                        <tr>
                          <th width="15%"></th>
                          <template v-if="info3.value.length > 0">
                            <th width="35%">
                              <div class="d-flex justify-content-around">
                                <div>{{ $t('走勢趨向') }}</div>
                                <div>{{ $t('上日低位') }}</div>
                                <div>{{ $t('上日高位') }}</div>
                                <div>{{ $t('上日收市') }}</div>
                              </div>
                            </th>
                          </template>
                          <th :width="info3.value.length > 0 ? '50%' : '85%'">
                            {{ $t('即將公佈的重要經濟數據') }}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td rowspan="2" class="bg th-none justify-content-center">
                            <p>{{ langName(info3.name) }}</p>
                            <p v-if="info3.name.zh_TW != info3.slug">{{ info3.slug }}</p>
                          </td>
                          <template v-if="info3.value.length > 0">
                            <td class="p-0 th-none">
                              <table class="w-100 bars">
                                <tr>
                                  <td :data-th="$t('走勢趨向')" rowspan="4" width="25%" class="flag xs-width">
                                    {{ handleFlag(info3.value[0]) }}
                                  </td>
                                  <td :data-th="$t('上日低位')" width="25%" class="xs-width">{{ info3.value[1] }}</td>
                                  <td :data-th="$t('上日高位')" width="25%" class="xs-width">{{ info3.value[2] }}</td>
                                  <td :data-th="$t('上日收市')" width="25%" class="xs-width">{{ info3.value[3] }}</td>
                                </tr>
                                <tr class="box-2 xs-none">
                                  <th></th>
                                  <th>{{ $t('阻力位') }}</th>
                                  <th>{{ $t('支持位') }}</th>
                                </tr>
                                <tr>
                                  <td class="bg">{{ $t('兌美元') }}</td>
                                  <td :data-th="$t('阻力位')">{{ info3.value[4] }}</td>
                                  <td :data-th="$t('支持位')">{{ info3.value[5] }}</td>
                                </tr>
                                <tr>
                                  <td class="bg">{{ $t('兌港元') }}</td>
                                  <td :data-th="$t('阻力位')">{{ info3.value[6] }}</td>
                                  <td :data-th="$t('支持位')">{{ info3.value[7] }}</td>
                                </tr>
                              </table>
                            </td>
                          </template>
                          <td :data-th="$t('即將公佈的重要經濟數據')" class="text-left px-md-5">
                            <span>{{ info3.txt | localeTxt(lang) }}</span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </template>
            </b-tab>
          </b-tabs>
          <div class="tips my-3">
            {{ $t('Market_News_Tips') }}
          </div>
        </div>
      </b-container>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import updateSeoInfo from '@/utils/meta'

import Lifecycle from '@/mixins/lifecycle'
import XChart from '@/components/charts'
import { lanToISO639, ISO639ToLan } from '@/utils'

export default {
  name: 'marketInfo',
  components: {
    XChart
  },
  data() {
    return {
      chartData: [],
      content: [],
      specialContent: ''
    }
  },
  mounted() {
    this.fetchData()
  },
  serverPrefetch() {
    return this.fetchData()
  },
  seoInfo() {
    try {
      return {
        title: this.page.metaTitle,
        description: this.page.metaDescription || this.page.metaTitle,
        keywords: this.page.metaKeywords || this.page.metaTitle,
        metas: this.page.metaExtras
      }
    } catch (err) {}
  },
  computed: {
    ...mapState('page', ['marketInfo']),
    ...mapState('common', ['page']),
    lang() {
      return lanToISO639(this.$getLocale())
    }
  },
  methods: {
    fetchData() {
      let path = this.$route.path.replace(`/${this.$route.params.lang}/`, '')
      path = path.replace(`preview/`, '')
      const cookies = this.$ssrContext && this.$ssrContext.cookies
      const { preview, id } = this.$route.query
      return new Promise((resolve, reject) => {
        Promise.all([
          this.fetchInitData(),
          this.$store.dispatch('common/FETCH_PAGE', {
            path,
            cookies,
            preview,
            id
          }),
          this.$store.dispatch('page/FETCH_MARKET_INFO', {
            path,
            cookies,
            preview,
            id
          })
        ])
          .finally(() => {
            resolve(updateSeoInfo.call(this))
            this.handleInit()
          })
          .catch(reject)
      })
    },
    async handleInit() {
      if (await this.marketInfo) this.content = this.marketInfo
      if (this.lang == 'en' && this.page) {
        this.specialContent = this.page.content
      }
      this.isMounted = true
      this.handleTime()
    },
    handleFlag(num) {
      return num == 0 ? '↗' : num == 1 ? '↘' : '→'
    },
    langName(name) {
      return name[lanToISO639(this.$route.params.lang)]
    },
    handleTime() {
      setTimeout(e => {
        this.fetchData()
      }, 60000)
    }
  }
}
</script>
